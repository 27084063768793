import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const Component = ({ body, className = '' }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      children={body}
      className={className}
      transformImageUri={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.GATSBY_STRAPI_URL}${uri}`
      }
    />
  )
}

export default Component
