import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Social from 'components/Social'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import LineLink from 'components/LineLink'
import LogoMarkSvg from '../../assets/svg/vi/mark.svg'
import * as styles from './styles.module.scss'

const Component = ({ data, locale }) => {
  let menu = data.menus.nodes[0].frontmatter[locale]

  let pages = data.pages.nodes
  pages = pages.map((page) => page.frontmatter[locale])
  menu?.main?.forEach((item) => {
    item.data = pages.find((page) => page.slug === item.page)
    item.submenu?.forEach((subitem) => {
      subitem.data = pages.find((page) => page.slug === subitem.page)
    })
  })

  let copyright = {
    en: `<span>© Copyright Shanghai Zhengshi Technology.</span> <span>All rights reserved.</span> <span><a href="https://lantern.digital" target="_blank">Website design</a>.</span>`,
    zh: `<span>©上海征世科技股份有限公司 版权所有</span> <span><a href="https://www.lanterndigital.com.cn" target="_blank">网站设计</a></span>`,
  }
  copyright = copyright[locale]
  if (process.env.GATSBY_SHOW_ICP === 'true') {
    copyright +=
      '<span class="before-image"><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备16006410号-1</a></span>'
    copyright += `<span class="has-image"><a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011802005049' target='_blank'><img src='/footer-icon.png' alt='' />沪公网安备 31011802005049号</a></span>`
  }

  return (
    <footer className={`bg-dark ${styles.component}`}>
      <Inview className='container fade-in up'>
        <div className={styles.logo}>
          <div className={styles.image}>
            <LinkWrap to='/'>
              <LogoMarkSvg />
            </LinkWrap>
          </div>
        </div>
        <div className={styles.links}>
          {menu?.main?.map((item, i) => (
            <Link content={item} key={i} i={i} />
          ))}
        </div>
        <div className={styles.social}>{/* <Social locale={locale} /> */}</div>
        <div className={`${styles.copyright} copyright`}>
          <p
            className='smallest'
            dangerouslySetInnerHTML={{ __html: copyright }}
          />
        </div>
      </Inview>
    </footer>
  )
}

const Link = ({ content }) => {
  const { submenu, altTitle, data } = content
  const { title, path } = data || {}
  const setTitle = altTitle ? altTitle : title
  return (
    <h5 className='smaller'>
      <LineLink content={{ text: setTitle, to: path, linkType: 'page' }} />
    </h5>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          menus: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: { templateKey: { eq: "menu" }, slug: { eq: "footer" } }
              }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  main {
                    page
                    altTitle
                    submenu {
                      page
                    }
                  }
                }
                zh {
                  title
                  main {
                    page
                    altTitle
                    submenu {
                      page
                    }
                  }
                }
              }
            }
          }
          pages: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "page" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  path
                }
                zh {
                  slug
                  title
                  path
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Component content={content} data={data} locale={locale} />
      )}
    />
  )
}
