import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ content, locale }) => {
  const { title, seo } = content || {}
  const companyName = {
    en: 'ZS Tech',
    zh: '征世科技',
  }
  const defaultTitle = `${companyName[locale]} | ${title}`
  let setTitle = defaultTitle
  if (seo?.title) {
    setTitle = `${companyName[locale]} | ${seo.title}`
  }
  const setDescription = seo?.description || null

  return (
    <Helmet>
      {setTitle && <title>{setTitle}</title>}
      {setDescription && <meta name='description' content={setDescription} />}
      {/* {keywords && <meta name='keywords' content={keywords} />} */}
    </Helmet>
  )
}

export default Seo
