import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import * as styles from './styles.module.scss'

const Component = ({ children, layoutProps, isDark = false }) => {
  const { setIsHeaderDark } = layoutProps
  const [isInView, setIsInView] = useState(false)
  const handleChange = (inView, entry) => {
    setIsInView(inView)
  }
  const ref = React.useRef(null)

  useEffect(() => {
    const scrollHandler = () => {
      if (isInView) {
        const element = ref.current
        if (element) {
          const parent = element.closest('.block')
          if (parent) {
            const header = document.querySelector('#header')
            const halfHeader = header.offsetHeight / 2
            let pastTop, beforeBottom
            if (window.scrollY > parent.offsetTop - halfHeader) {
              pastTop = true
            }
            if (
              window.scrollY <
              parent.offsetTop + parent.offsetHeight - halfHeader
            ) {
              beforeBottom = true
            }
            if (pastTop && beforeBottom) {
              setIsHeaderDark(isDark)
            }
            // else {
            //   setBgFunction(false)
            // }
          }
        }
      }
    }
    scrollHandler() // run once when page first loads
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [isInView])

  return (
    <div className={`header-version-wrap ${styles.component}`} ref={ref}>
      {/* <h1>is in view: {isInView ? 'true' : 'false'}</h1> */}
      <InView onChange={handleChange}>{children}</InView>
    </div>
  )
}

export default Component
